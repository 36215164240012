import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, IconButton, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import bookImage from '../../../Assets/img1.jpg'; // Default book image as fallback
import { getAuthors } from '../../../Services/AdminServices'; // Ensure this service exists

function Download() {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookDetails: initialBookDetails, userDetails: initialUserDetails } = location.state || {};

  const [bookDetails, setBookDetails] = useState(initialBookDetails);
  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [authors, setAuthors] = useState({});
  const [paymentScreenshot, setPaymentScreenshot] = useState(null); // State for the uploaded screenshot
  const [mobileNumber, setMobileNumber] = useState(''); // State for mobile number input

  // Fetch authors and map IDs to names
  const fetchAuthors = async () => {
    try {
      const response = await getAuthors();
      const authorsData = response?.data?.authors || [];
      const authorsMap = Object.fromEntries(authorsData.map((author) => [author._id, author.name]));
      setAuthors(authorsMap);

      // Update book details with the author's name
      if (initialBookDetails && initialBookDetails.author) {
        setBookDetails({
          ...initialBookDetails,
          authorName: authorsMap[initialBookDetails.author] || 'Unknown Author',
        });
      }
    } catch (error) {
      
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); 
    fetchAuthors();
  }, []);

  const handleCopyUpi = () => {
    if (userDetails?.upiId) {
      navigator.clipboard.writeText(userDetails.upiId);
      alert('UPI ID copied to clipboard!');
    } else {
      alert('UPI ID not available');
    }
  };

  const handleShareViaWhatsApp = async () => {
    if (!mobileNumber) {
      alert('Please enter your mobile number.');
      return;
    }

    if (paymentScreenshot) {
      const message = `I have made a payment for the book '${bookDetails?.name || 'Book'}' by ${bookDetails?.authorName || 'Author'}.\nPrice: ₹${bookDetails?.price || 'N/A'}.\nMobile: ${mobileNumber}`;
      const phoneNumber = mobileNumber.replace(/[^0-9]/g, '');
      const adminPhoneNumber = userDetails?.whatsappNumber?.replace(/[^0-9]/g, '');

      if (adminPhoneNumber) {
        window.open(`https://wa.me/${adminPhoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
      }
      window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
      alert('Message with payment details shared successfully!');
    } else {
      alert('Please upload a payment screenshot.');
    }
  };

  if (!bookDetails) return null;

  return (
    <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
      <Box
        sx={{
          borderRadius: '12px',
          padding: '20px',
         
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#f9f9f9',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'left'  }}>
            <Typography variant="h4" component="h1" style={{ color: '#AD62AA', fontWeight: 'bold' }}>
              {bookDetails.name}
            </Typography>
            <Typography variant="h6" component="h2" style={{ color: 'gray' }}>
              By <span style={{ color: 'black' }}>{bookDetails.authorName || 'Unknown Author'}</span>
            </Typography>

            {bookDetails.price && (
              <Typography variant="h5" style={{ color: '#AD62AA', marginTop: '10px' }}>
                Price: ₹{bookDetails.price}
              </Typography>
            )}

            <Typography variant="body1" style={{ marginTop: '10px', color: 'gray' }}>
              {bookDetails.shortDescription || 'No description available'}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} style={{ marginTop: '10px' }}>
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
            <img
              src={bookDetails.coverPhoto?.url || bookImage}
              alt="Book cover"
              style={{
                width: '100%',
                maxWidth: '300px',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ backgroundColor: '#fff',ml:{xs:4,sm:0} ,padding: '20px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
              <Typography variant="h5" component="h2" sx={{
                fontWeight: 'bold', 
                marginBottom: '20px', 
                color: '#AD62AA',
                borderBottom: '2px solid #AD62AA',
                paddingBottom: '10px'
              }}>
                Payment Instructions
              </Typography>

              <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="body1" sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#AD62AA', fontWeight: 'bold', marginRight: '10px' }}>1.</span>
                  Make a payment via UPI ID
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#AD62AA', fontWeight: 'bold', marginRight: '10px' }}>2.</span>
                  Take a screenshot of the payment
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#AD62AA', fontWeight: 'bold', marginRight: '10px' }}>3.</span>
                  Share the screenshot via WhatsApp
                </Typography>
              </Box>

              <Box sx={{
                marginBottom: '20px',
               
                padding: '15px',
                borderRadius: '8px'
              }}>
                <Typography variant="body1" sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px'
                }}>
                  UPI ID: 
                  <span style={{ color: '#AD62AA', marginLeft: '10px', marginRight: '10px' }}>
                    {userDetails?.upiId || 'Not available'}
                  </span>
                  <IconButton 
                    onClick={handleCopyUpi} 
                    size="small"
                    sx={{ color: '#AD62AA' }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Typography>

                {userDetails?.qrCode?.url && (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      src={userDetails?.qrCode?.url || 'fallback-qr-image.jpg'}  
                      alt="UPI QR Code"
                      style={{
                        width: '200px',
                        height: 'auto',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box sx={{
                marginTop: '20px',
                padding: '15px',
               
                borderRadius: '8px',
                
              }}>
                <Typography variant="h6" sx={{
                  fontWeight: 'bold',
                  color: '#AD62AA',
                  marginBottom: '15px'
                }}>
                  Payment Confirmation
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                  <Typography variant="body1" sx={{ marginRight: '10px' }}>
                    Attach payment screenshot:
                  </Typography>
                  <input
                    id="payment-screenshot-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        setPaymentScreenshot(file);
                        alert(`File selected: ${file.name}`);
                      }
                    }}
                  />
                  <IconButton
                    component="label"
                    htmlFor="payment-screenshot-upload"
                    sx={{ color: '#AD62AA' }}
                  >
                    <AttachFileIcon />
                  </IconButton>
                  {paymentScreenshot && (
                    <Typography
                      variant="body2"
                      sx={{
                        marginLeft: '10px',
                        color: 'gray',
                        fontStyle: 'italic',
                      }}
                    >
                      {paymentScreenshot.name}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ marginRight: '10px' }}>
                    Mobile Number:
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Enter your mobile number"
                    size="small"
                    sx={{
                      width: '100%',
                      maxWidth: '200px',
                    }}
                  />
                </Box>

                <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                  <IconButton 
                    onClick={handleShareViaWhatsApp} 
                    sx={{ color: '#25D366' }}
                    disabled={!mobileNumber || !paymentScreenshot}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Download;
