import React, { useEffect, useState } from 'react';
import { Button, Icon, InputAdornment, TextField, Typography, Grid, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import toast, { Toaster } from 'react-hot-toast';
import { getCurrentUser, login } from '../Services/AdminServices';
import { setUser } from '../redux/slices/userSlice';
import logoo from '../Assets/logoo.png';

const Login = () => {
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("authtoken");
    if (token) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const loginResponse = await login({ email, password });
      const token = loginResponse?.data?.token;
      sessionStorage.setItem("authtoken", token);
      const currentUserResponse = await getCurrentUser();
      dispatch(setUser(currentUserResponse?.data));
      toast.success(loginResponse?.data?.message || "Login successful");
      navigate('/admin');
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred during login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      {isDesktop && (
        <Grid item xs={12} sm={7}>
          <img src={logoo} alt="Logo" style={{ width: '100%', height: '100%' }} />
        </Grid>
      )}
      <Grid item xs={12} sm={isDesktop ? 5 : 12} style={{ backgroundColor: '#F3F4F6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '400px', padding: '20px', background: 'white', borderRadius: '16px' }}>
          <h2 style={{ color: '#4a148c', textAlign: 'center', marginBottom: '20px' }}>Login</h2>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder='Email'
            margin="dense"
            variant="outlined"
            fullWidth
            sx={{ mb: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon><EmailOutlinedIcon sx={{ color: '#4a148c' }} /></Icon>
                </InputAdornment>
              )
            }}
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder='Password'
            margin="dense"
            variant="outlined"
            fullWidth
            type={showPassword ? "text" : "password"}
            sx={{ mb: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon onClick={() => setShowPassword(!showPassword)} sx={{ cursor: 'pointer' }}>
                    {showPassword ? <VisibilityIcon sx={{ color: '#4a148c' }} /> : <VisibilityOffIcon sx={{ color: '#4a148c' }} />}
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          {/* <Link to="/forget" style={{ color: '#4a148c', marginBottom: '20px', textAlign: 'right' }}>Forgot Password?</Link> */}
          <Button
            type='button'
            style={{ backgroundColor: '#4a148c', color: 'white', borderRadius: 16 }}
            fullWidth
            variant='contained'
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
          {/* <Typography variant="body2" style={{ textAlign: 'center', marginTop: '20px' }}>
            Don't have an account? <Link to="/register" style={{ color: '#4a148c' }}>Register</Link>
          </Typography> */}
          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
