import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid, InputAdornment } from '@mui/material';
import ResponsiveDrawer from '../Components/Drawer/Drawer'; 
import { postCourse, updateCourse, getCourseById } from '../../Services/AdminServices';  
import { Toaster, toast } from 'react-hot-toast';
import VideoCallIcon from '@mui/icons-material/VideoCall';

function NewCourse(props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); 
  const isEditMode = Boolean(id);

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    classroomJoinLink: '',
    coverPhoto: null,  
    coverPreview: '',  
  });

  const [errors, setErrors] = useState({
    name: false,
    description: false,
    classroomJoinLink: false,
    coverPhoto: false,
  });

  useEffect(() => {
    if (isEditMode) {
      const fetchCourseData = async () => {
        try {
          const response = await getCourseById(id);
          if (response?.data?.success) {
            const course = response.data.course;
            setFormData({
              name: course.name || '',
              description: course.description || '',
              classroomJoinLink: course.classroomJoinLink || '',
              coverPhoto: null, 
              coverPreview: course.coverPhoto?.url || '', 
            });
          } else {
            toast.error('Course not found', {
              duration: 4000,
              position: 'top-center',
            });
          }
        } catch (error) {
          toast.error('Failed to fetch course data', {
            duration: 4000,
            position: 'top-center',
          });
       
        }
      };
      
      fetchCourseData();
    }
  }, [isEditMode, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: false }));
  };

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        coverPhoto: file,
        coverPreview: URL.createObjectURL(file),
      }));
      setErrors(prev => ({ ...prev, coverPhoto: false }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      description: !formData.description,
      classroomJoinLink: !formData.classroomJoinLink,
      coverPhoto: isEditMode ? false : !formData.coverPhoto, 
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields", {
        duration: 4000,
        position: 'top-center',
        icon: '⚠️',
      });
      return;
    }

    setIsLoading(true);
    const submitFormData = new FormData();
    submitFormData.append('name', formData.name);
    submitFormData.append('description', formData.description);
    submitFormData.append('classroomJoinLink', formData.classroomJoinLink);

    if (formData.coverPhoto) {
      submitFormData.append('coverPhoto', formData.coverPhoto);
    }

    try {
      const loadingToast = toast.loading(
        isEditMode ? 'Updating course...' : 'Creating course...', 
        { position: 'top-center' }
      );

      let response = isEditMode
        ? await updateCourse(id, submitFormData)
        : await postCourse(submitFormData);

      if (response?.data?.success) {
        toast.dismiss(loadingToast);
        
        toast.success(
          isEditMode 
            ? `Course "${formData.name}" updated successfully!` 
            : `Course "${formData.name}" created successfully!`, 
          {
            duration: 5000,
            position: 'top-center',
            icon: '✅',
            style: {
              background: '#4CAF50',
              color: 'white',
              padding: '16px',
              borderRadius: '8px',
            },
          }
        );

        setTimeout(() => {
          navigate('/admin/courses');
        }, 1500);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to process the request', {
        duration: 4000,
        position: 'top-center',
        icon: '❌',
      });
    
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Toaster />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: { xs: 4, sm: 10 } }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {isEditMode ? 'Edit Course' : 'Add Course'}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
                >
                  Upload Cover Photo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleCoverPhotoChange}
                  />
                </Button>
                {formData.coverPreview && (
                  <Box mt={2}>
                    <img
                      src={formData.coverPreview}
                      alt="Cover Preview"
                      style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
                    />
                  </Box>
                )}
                {errors.coverPhoto && <Typography color="error">Cover photo is required</Typography>}
              </Box>

              <TextField
                label="Course Name"
                fullWidth
                margin="normal"
                value={formData.name}
                onChange={handleInputChange}
                name="name"
                required
                error={errors.name}
                helperText={errors.name ? "Course name is required" : ""}
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />

              <TextField
                label="Resource Person
"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleInputChange}
                name="description"
                required
                error={errors.description}
                helperText={errors.description ? "Description is required" : ""}
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />

              <TextField
                label="Google Meet Link"
                fullWidth
                margin="normal"
                value={formData.classroomJoinLink}
                onChange={handleInputChange}
                name="classroomJoinLink"
                placeholder="https://meet.google.com/xxx-xxxx-xxx"
                required
                error={errors.classroomJoinLink}
                helperText={errors.classroomJoinLink ? "Please enter a valid Google Meet link" : ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VideoCallIcon color={errors.classroomJoinLink ? "error" : "primary"} />
                    </InputAdornment>
                  ),
                }}
                sx={{ backgroundColor: '#e0dab8', borderColor: '#829B3E' }}
              />

              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isLoading}
                  sx={{ 
                    backgroundColor: '#A56266', 
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#8b4f53'
                    } 
                  }}
                >
                  {isLoading ? 'Processing...' : (isEditMode ? 'Update Course' : 'Add Course')}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewCourse;