import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgetPassword from './Auth/ForgetPassword';
import Verify from './Auth/Verify';
import Reset from './Auth/Reset';
import Navbar from './User/Navbar/Navbar';
import Footer from './User/Components/HomePage/Footer';

import { finishLoadingUser } from './redux/slices/userLoadingSlice';
import { setUser, unsetUser } from './redux/slices/userSlice';
import { getCurrentUser } from './Services/AdminServices';
import AdminRoutes from './Routes/AdminRoutes';
import UserRoutes from './Routes/UserRoutes';

// Define theme with Noto Serif font
const theme = createTheme({
  typography: {
    fontFamily: `"Noto Serif", "Noto Serif Malayalam", serif`,
  },
});

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(state => state.user.value);
  const isLoading = useSelector(state => state.userLoading.isLoading);

  useEffect(() => {
    const checkUserAuth = async () => {
      const token = sessionStorage.getItem("authtoken");
      if (token) {
        try {
          const response = await getCurrentUser();
          dispatch(setUser(response?.data));
        } catch (err) {
          sessionStorage.removeItem("authtoken");
          dispatch(unsetUser());
        }
      } else {
        dispatch(unsetUser());
      }
      dispatch(finishLoadingUser());
    };

    checkUserAuth();
  }, [dispatch]);

  // Define admin paths
  const isAdminPath = location.pathname.startsWith('/admin');
  
  // Define auth paths
  const isAuthPath = ['/login', '/register', '/reset', '/forget', '/verify'].includes(location.pathname);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Show navbar and footer only if not on auth or admin pages */}
        {!isAuthPath && !isAdminPath && <Navbar />}
        <main>
          <Routes>
            {/* User routes */}
            <Route path="/*" element={<UserRoutes />} />
            {/* Auth routes */}
            <Route path="/login" element={user ? <Navigate to="/admin" replace /> : <Login />} />
            <Route path="/register" element={user ? <Navigate to="/admin" replace /> : <Register />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/forget" element={<ForgetPassword />} />
            <Route path="/verify/:id" element={<Verify />} />
            {/* Admin routes */}
            <Route path="/admin/*" element={user ? <AdminRoutes /> : <Navigate to="/login" state={{ from: location }} replace />} />
          </Routes>
        </main>
        {!isAuthPath && !isAdminPath && <Footer />}
      </div>
    </ThemeProvider>
  );
};

export default App;
