import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllCategories,
  postNewsarticles,
  getAuthors,
  uploadPhoto,
  getNewsarticlesById,
  updateNewsarticles,
} from '../../Services/AdminServices';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import toast, { Toaster } from 'react-hot-toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';

const CreateNews = ({ ...props }) => {
  const navigate = useNavigate();
  const reactQuillRef = useRef(null);
  const { slug } = useParams();

  const [formData, setFormData] = useState({
    categoryType: 'news',
    selectedCategory: '',
    selectedAuthor: '',
    title: '',
    subtitle: '',
    slug: '',
    tags: '',
    content: ''
  });

  // UI state
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authorLoading, setAuthorLoading] = useState(false);
  const [articleLoading, setArticleLoading] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [slugError, setSlugError] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const generateSlug = (title, existingSlug = '') => {
    let newSlug = "";
    let today = new Date();
    
    if (!existingSlug) {
      // Replace non-word characters, spaces, and preserve Malayalam characters
      let name = title.toLowerCase()
        .replace(/[^\w\u0D00-\u0D7F]/g, '_')
        .replace(/_{2,}/g, '_') // Replace multiple consecutive underscores with single
        .replace(/^_|_$/g, ''); // Remove leading and trailing underscores
      
      newSlug = name + "_" + moment(today).format('MMMM_DD_YYYY').toLowerCase();
    } else {
      newSlug = existingSlug.toLowerCase() + "_" + moment(today).format('MMMM_DD_YYYY').toLowerCase();
    }
    
    return newSlug;
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.title) errors.title = 'Title is required';
    if (!formData.selectedCategory) errors.category = 'Category is required';
    if (!formData.selectedAuthor) errors.author = 'Author is required';
    if (!formData.content) errors.content = 'Content is required';
    if (slugError) errors.slug = slugError;
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSlugChange = (value) => {
    // Only allow alphanumeric, underscores, and Malayalam characters
    let sanitizedValue = value.toLowerCase()
      .replace(/[^\w\u0D00-\u0D7F]/g, '_')
      .replace(/_{2,}/g, '_')
      .replace(/^_|_$/g, '');

    if (sanitizedValue !== value) {
      setSlugError('Slug can only contain letters, numbers, and underscores');
    } else {
      setSlugError('');
    }

    setFormData(prev => ({
      ...prev,
      slug: sanitizedValue
    }));
  };

  const handleFormChange = (field, value) => {
    setFormData(prev => {
      const newData = { ...prev, [field]: value };
      
      // Generate slug only in create mode when title changes and slug is empty
      if (field === 'title' && !isEditMode && !prev.slug) {
        newData.slug = generateSlug(value);
      }
      
      // Clear related form errors
      setFormErrors(prev => ({
        ...prev,
        [field]: undefined
      }));
      
      return newData;
    });
  };

  // Fetch categories based on type
  const fetchCategories = async (type) => {
    try {
      setLoading(true);
      const response = await getAllCategories();
      if (response?.data?.categories) {
        const filteredCategories = response.data.categories.filter(
          (category) => category.type === type
        );
        setCategories(filteredCategories);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Failed to load categories');
    } finally {
      setLoading(false);
    }
  };

  // Fetch authors
  const fetchAuthors = async () => {
    try {
      setAuthorLoading(true);
      const response = await getAuthors();
      if (response?.data?.authors) {
        setAuthors(response.data.authors);
      }
    } catch (error) {
      console.error('Error fetching authors:', error);
      toast.error('Failed to load authors');
    } finally {
      setAuthorLoading(false);
    }
  };

  // Image handler for ReactQuill
  const imageHandler = useCallback(async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (!input.files?.length) return;

      const file = input.files[0];
      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size should be less than 5MB');
        return;
      }

      if (!file.type.startsWith('image/')) {
        toast.error('Please upload only image files');
        return;
      }

      const formData = new FormData();
      formData.append('image', file);

      try {
        setSpinner(true);
        const loadingToast = toast.loading('Uploading image...');
        
        const response = await uploadPhoto(formData);
        if (response?.data?.file?.url) {
          const quill = reactQuillRef.current.getEditor();
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', response.data.file.url);
          quill.setSelection(range.index + 1);
          toast.success('Image uploaded successfully', { id: loadingToast });
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        toast.error('Failed to upload image');
      } finally {
        setSpinner(false);
      }
    };
  }, []);

  // Fetch article data for editing
  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) return;

      try {
        setArticleLoading(true);
        setIsEditMode(true);
        
        const response = await getNewsarticlesById(slug);
        if (response?.data?.success && response?.data?.article) {
          const article = response?.data?.article;
          
          const categoryId = typeof article.category === 'object' ? article.category._id : article.category;
          const authorId = typeof article.author === 'object' ? article.author._id : article.author;
          
          setFormData({
            categoryType: article.type || 'news',
            selectedCategory: categoryId,
            selectedAuthor: authorId,
            title: article.title || '',
            subtitle: article.subtitle || '',
            slug: article.slug || '',
            tags: Array.isArray(article.tags) ? article.tags.join(', ') : article.tags || '',
            content: article.content || ''
          });

          await Promise.all([
            fetchCategories(article?.type || 'news'),
            fetchAuthors()
          ]);
        } else {
          toast.error('Failed to load article data');
          navigate('/admin/publish');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        toast.error('Error loading article');
        navigate('/admin/publish');
      } finally {
        setArticleLoading(false);
      }
    };

    fetchArticle();
  }, [slug, navigate]);

  useEffect(() => {
    if (!isEditMode) {
      fetchCategories(formData.categoryType);
      fetchAuthors();
    }
  }, [formData.categoryType, isEditMode]);

  const modules = {
    toolbar: {
      container: [
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
        ['image'],
        [{ color: [] }, { background: [] }],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form before submission
    if (!validateForm()) {
      toast.error('Please fill in all required fields correctly');
      return;
    }
  
    // Generate the final slug if empty
    let newSlug = "";
    let today = new Date();
    if (formData.slug === "") {
      let name = formData.title.replace(/[^\w\u0D00-\u0D7F]/g, '_'); // Support for Unicode characters
      newSlug = name + "_" + moment(today).format('MMMM_DD_YYYY');
    } else {
      newSlug = formData.slug + "_" + moment(today).format('MMMM_DD_YYYY');
    }
  
    // Prepare article data
    const articleData = {
      type: formData.categoryType,
      category: formData.selectedCategory,
      title: formData.title,
      subtitle: formData.subtitle,
      slug: newSlug, 
      content: formData.content,
      tags: formData.tags.split(',').map(tag => tag.trim()).filter(Boolean),
      author: formData.selectedAuthor,
    };
  
    let loadingToast;
  
    try {
      setLoading(true);
      loadingToast = toast.loading(isEditMode ? 'Updating article...' : 'Creating article...');
  
      // Submit article data (edit or create mode)
      const response = isEditMode
        ? await updateNewsarticles(slug, articleData)
        : await postNewsarticles(articleData);
  
      if (response?.data?.success) {
        toast.success(
          isEditMode ? 'Article updated successfully' : 'Article created successfully',
          { id: loadingToast }
        );
        navigate('/admin/publish');
      } else {
        // Handle specific error case for duplicate slug
        if (response?.data?.message === "Slug already exists. Please use a different slug.") {
          setSlugError('This slug already exists. Please choose a different one.');
          toast.error('This slug already exists. Please choose a different one.', { id: loadingToast });
        } else {
          throw new Error('Operation failed');
        }
      }
    } catch (error) {
      console.error('Error submitting article:', error);
      // Check if the error response contains the slug exists message
      if (error.response?.data?.message === "Slug already exists. Please use a different slug.") {
        setSlugError('This slug already exists. Please choose a different one.');
        toast.error('This slug already exists. Please choose a different one.', 
          { id: loadingToast }
        );
      } else {
        toast.error(
          isEditMode ? 'Failed to update article' : 'Failed to create article',
          { id: loadingToast }
        );
      }
    } finally {
      setLoading(false);
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };
  
  

  if (articleLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ padding: { xs: 4, sm: 4 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: { xs: 4, sm: 8 } }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {isEditMode ? 'Edit News' : 'Create News'}
            </Typography>

            

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Category Type</InputLabel>
              <Select
                value={formData.categoryType}
                onChange={(e) => handleFormChange('categoryType', e.target.value)}
                label="Category Type"
                disabled={isEditMode}
              >
                <MenuItem value="news">News</MenuItem>
                <MenuItem value="articles">Articles</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} error={!!formErrors.category}>
              <InputLabel>Category</InputLabel>
              <Select
                value={formData.selectedCategory}
                onChange={(e) => handleFormChange('selectedCategory', e.target.value)}
                label="Category"
                disabled={loading || categories.length === 0}
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.category && (
                <Typography color="error" variant="caption">
                  {formErrors.category}
                </Typography>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} error={!!formErrors.author}>
              <InputLabel>Author</InputLabel>
              <Select
                value={formData.selectedAuthor}
                onChange={(e) => handleFormChange('selectedAuthor', e.target.value)}
                label="Author"
                disabled={authorLoading || authors.length === 0}
              >
                {authors.map((author) => (
                  <MenuItem key={author._id} value={author._id}>
                    {author.name}
                  </MenuItem>
                ))}
              </Select>
              {formErrors.author && (
                <Typography color="error" variant="caption">
                  {formErrors.author}
                </Typography>
              )}
            </FormControl>

            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={formData.title}
              onChange={(e) => handleFormChange('title', e.target.value)}
              error={!!formErrors.title}
              helperText={formErrors.title}
              sx={{ mb: 2 }}
            />


<TextField
              label="Slug"
              variant="outlined"
              fullWidth
              value={formData.slug}
              onChange={(e) => handleSlugChange(e.target.value)}
              disabled={isEditMode}
              helperText={slugError || 'Leave blank to auto-generate from title. Only letters, numbers, and underscores allowed.'}
              error={!!slugError || !!formErrors.slug}
              sx={{ mb: 2 }}
            />


            <TextField
              label="Subtitle"
              variant="outlined"
              fullWidth
              value={formData.subtitle}
              onChange={(e) => handleFormChange('subtitle', e.target.value)}
              sx={{ mb: 2 }}
            />

            <TextField
              label="Tags (comma separated)"
              variant="outlined"
              fullWidth
              value={formData.tags}
              onChange={(e) => handleFormChange('tags', e.target.value)}
              sx={{ mb: 2 }}
            />

            <ReactQuill
              value={formData.content}
              onChange={(value) => handleFormChange('content', value)}
              theme="snow"
              placeholder="Write your article content here..."
              modules={modules}
              style={{ minHeight: '300px', marginBottom: '20px' }}
              ref={reactQuillRef}
            />
            {formErrors.content && (
              <Typography color="error" variant="caption" sx={{ mt: -2, mb: 2, display: 'block' }}>
                {formErrors.content}
              </Typography>
            )}

<Box display="flex" justifyContent="flex-end" mt={2} gap={4}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate('/admin/publish')}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: '#d18898', color: 'white' }}
                onClick={handleSubmit}
                disabled={loading || !!slugError}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  isEditMode ? 'Update News' : 'Publish News'
                )}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default CreateNews;