import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, Button, Card, TextField, Typography, Grid, Container, FormControl, CircularProgress 
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { postAuthor, updateAuthors, getAuthorsById } from '../../Services/AdminServices'; 
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
import toast, { Toaster } from 'react-hot-toast';

const CardStyled = styled(Card)({
  backgroundColor: "#fff",
  boxShadow: "none",
  marginBottom: 20,
  padding: 20,
  width: "70%",
  "@media (max-width: 1200px)": { width: "70%" },
  "@media (max-width: 992px)": { width: "100%" },
  "@media (max-width: 768px)": { width: "100%" },
});

const NewAuthor = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [photo, setPhoto] = useState(null);
  const [existingPhoto, setExistingPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(!!id);
  const [nameError, setNameError] = useState(false);
  const [photoError, setPhotoError] = useState(false);

  // Fetch Author Data (Edit Mode)
  useEffect(() => {
    if (id) {
      const fetchAuthor = async () => {
        try {
          setFetching(true);
          const response = await getAuthorsById(id);
          const author = response?.data;
          if (author) {
            setName(author.name || '');
            setBio(author.bio || '');
            setExistingPhoto(author.photo || null);
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Error fetching author details');
        } finally {
          setFetching(false);
        }
      };
      fetchAuthor();
    }
  }, [id]);

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setNameError(!name);
    setPhotoError(!photo && !existingPhoto && !id);

    if (!name || (!photo && !existingPhoto && !id)) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('name', name);
    formData.append('bio', bio);
    if (photo) formData.append('photo', photo);

    try {
      let response;
      if (id) {
        response = await updateAuthors(id, formData);
        toast.success(response?.data?.message || 'Author updated successfully');
      } else {
        response = await postAuthor(formData);
        toast.success(response?.data?.message || 'Author created successfully');
      }
      setTimeout(() => navigate('/admin/authors'), 2000);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error saving author');
    } finally {
      setLoading(false);
    }
  };

  // Handle Photo Upload
  const handlePhotoChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setPhoto(file);
      setPhotoError(false);
    } else {
      toast.error("Please upload a valid image file");
    }
  }, []);

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer />
        </Grid>

        <Grid item xs={12} lg={10} sx={{ mt: { xs: 4, sm: 10 } }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {id ? 'Edit Author' : 'Add New Author'}
            </Typography>
          </Box>

          <CardStyled>
            {fetching ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <TextField
                  error={nameError}
                  label="Author Name"
                  placeholder="Enter Author Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mb: 3 }}
                  helperText={nameError && "Please enter the name"}
                />

                <TextField
                  label="Bio"
                  placeholder="Enter Author Bio"
                  variant="outlined"
                  fullWidth
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  sx={{ mb: 3 }}
                  multiline
                  rows={4}
                />

                <FormControl fullWidth sx={{ mb: 3 }} required error={photoError}>
                  <input
                    type="file"
                    id="photo-upload"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="photo-upload">
                    <Button variant="outlined" component="span" fullWidth>
                      {photo ? 'Change Photo' : 'Upload Photo'}
                    </Button>
                  </label>

                  {(photo || existingPhoto) && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                      <img
                        src={photo ? URL.createObjectURL(photo) : existingPhoto}
                        alt="Selected"
                        style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                      />
                    </Box>
                  )}
                </FormControl>

                <Button
                  variant="contained"
                  style={{ backgroundColor: "#A56266", color: "white", borderRadius: 10, fontSize: 15 }}
                  onClick={handleSubmit}
                  disabled={loading || fetching}
                >
                  {loading ? 'Saving...' : id ? 'Update Author' : 'Save Author'}
                </Button>
              </Box>
            )}
          </CardStyled>
          <Toaster position="top-center" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewAuthor;
