import React, { useState } from 'react';   
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Button, 
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Buttons from '../Components/HomePage/Buttons';
import MenuIcon from '@mui/icons-material/Menu';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import logo from '../../Assets/hero.png';
import { Facebook, WhatsApp } from '@mui/icons-material';
import { Youtube } from 'lucide-react';


const styles = {
  footer: {
    bgcolor: '#0a2240',
    color: 'white',
    py: { xs: -8, sm: 4 },  // Reduced padding for xs screens (py: 3)
    mt: 'auto',
  },
  contactTitle: {
    fontWeight: 'bold',
    
    mb: 2,
    color: '#4a90e2',
    display: 'flex',
    alignItems: 'center',
  },
  contactTitleSocial: {
    fontWeight: 'bold',
    mb: 2,
    color: '#4a90e2',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  contactInfo: {
    mb: 1,
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
  socialLink1: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
  emailInput: {
    mb: 2,
    bgcolor: 'white',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#4a90e2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4a90e2',
      },
    },
  },
  subscribeButton: {
    py: 1,
    px: 2,
    bgcolor: '#4a90e2',
    '&:hover': {
      bgcolor: '#3a78c2',
    },
  },
  divider: {
    my: 4,
    bgcolor: 'rgba(255, 255, 255, 0.2)',
  },
  policyLink: {
    color: 'white',
    '&:hover': { color: '#4a90e2' },
  },
};

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));  // Check for mobile sizes (md and below)
  const isXs = useMediaQuery(theme.breakpoints.down('sm')); // Check for extra-small screen (xs and below)

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'Articles', link: '/articles' },
    { text: 'USA ', link: '/usachapter' },
    { text: 'Publications', link: '/publications' },
    { text: 'Magazine', link: '/magazine' },
    { text: 'Courses', link: '/courses' },
    { text: 'About', link: '/about' },
    { text: 'Contact', link: '/contact' },
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box>
      <AppBar 
        position="static" 
        sx={{
          backgroundColor: '#0a2240',
          boxShadow: 'none', 
          padding: isMobile ? '8px 16px' : '16px 24px',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ textDecoration: 'none' }}>
              <img 
                src={logo} 
                alt="Logo" 
                style={{
                  height: isMobile ? '50px' : '70px',
                  marginRight: '16px',
                  backgroundColor: 'white',
                  padding: '8px',
                  borderRadius: '8px',
                }}
              />
            </Link>

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  color: 'white',
                  fontSize: isMobile ? '1rem' : '1.5rem',
                }}
              >
                SHARON WRITERS FORUM
              </Typography>

              <Typography
                variant="h9"
                sx={{
                  color:"#e8eaf6",
                  fontWeight: 100,
                  fontSize: isMobile ? '0.7rem' : '1rem',
                  textTransform: 'none',
                  marginTop: '0.2rem',
                }}
              >
                Writers Wing of Sharon Fellowship Church
              </Typography>
            </Box>
          </Box>

          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  component={Link}
                  to={item.link}
                  sx={{
                    color: 'white',
                    
                    fontWeight: 500,
                    margin: '0 6px',
                    textTransform: 'none',
                    fontSize: '1rem',
                    '&:hover': {
                      color: '#D91656',
                    },
                  }}
                >
                  {item.text}
                </Button>
              ))}

              {/* Social Media Links */}
              
            <IconButton sx={{ color: 'white', ml: -1 }} href="https://www.facebook.com/share/19YivZeHEB/" target="_blank">
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: 'white', ml: 1 }} href="https://youtube.com/@sharonwriters?si=B7OsAFmlqXQJrsN9" target="_blank">
              <YouTubeIcon />
            </IconButton>
            <IconButton sx={{ color: 'white', ml: 1 }} href="https://chat.whatsapp.com/LI6j7ICA29N16NTXTbjuyB" target="_blank">
              <WhatsApp />
            </IconButton>
          
            </Box>
          )}

          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerToggle}
              sx={{
                color: 'white',
                mr: -3
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: '230px',
            backgroundColor: '#0a2240',
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                height: '100px',
                marginBottom: '8px',
                backgroundColor: 'white',
                padding: '8px',
                borderRadius: '8px',
              }}
            />
          </Link>
          <Typography
            variant="h7"
            sx={{
              
              fontWeight: 600,
              color: 'white',
              textTransform: 'uppercase',
              textAlign: 'center',
            }}
          >
            SHARON WRITERS FORUM
          </Typography>
        </Box>

        {/* Menu Items */}
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.link}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  sx: {
                    
                    color: 'white',
                    '&:hover': { color: '#D91656' },
                  },
                }}
              />
            </ListItem>
          ))}
        </List>

        {/* Footer Content */}
        <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 'auto' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <EmailIcon sx={{ color: 'white', mr: 1 }} />
            <Typography sx={{ color: 'white' }}>info@sharonwriters.com</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems:'flex-start', mb: 2 }}>
            <PhoneIcon sx={{ color: 'white', mr: 1 }} />
            <Typography sx={{ color: 'white' }}>+91 469 260 7979</Typography>
          </Box>

          {/* Social Media Icons Below Phone Number */}
          <Box sx={{ display: 'flex', justifyContent:'left', }}>
            <IconButton sx={{ color: 'white', ml: -1 }} href="https://www.facebook.com/share/19YivZeHEB/" target="_blank">
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: 'white', ml: 1 }} href="https://youtube.com/sharonwritersforum" target="_blank">
              <YouTubeIcon />
            </IconButton>
            <IconButton sx={{ color: 'white', ml: 1 }} href="https://chat.whatsapp.com/LI6j7ICA29N16NTXTbjuyB" target="_blank">
              <WhatsApp />
            </IconButton>
          </Box>
        </Box>
      </Drawer>

      {/* Render Buttons only on Medium and Larger Screens (hide on xs) */}
      {!isXs && (
        <Box sx={{ mt: 2.5 }}>
          <Buttons />
        </Box>
      )}
    </Box>
  );
}

export default Navbar;
