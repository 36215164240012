import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import course from '../../../Assets/cours.jpg';
import Public from '../../../Assets/public.jpg';
import news from '../../../Assets/news.jpg';
import magazine from '../../../Assets/magazine.jpg';
import './Buttons.css'; // Make sure this file contains the updated styles

function Buttons() {
  return (
    <Box className="card-container">
      
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Box className="button-box button-primary">
              <Link to="/courses" className="button-link">
                <Box className="button-content">
                  <img src={course} alt="Explore Courses" className="button-image" />
                  <Typography variant="h6" className="button-text">
                    Explore Courses
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box className="button-box button-secondary">
              <Link to="/publications" className="button-link">
                <Box className="button-content">
                  <img src={Public} alt="View Publications" className="button-image" />
                  <Typography variant="h6" className="button-text">
                    View Publications
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>

          
          <Grid item xs={12} sm={6} md={3}>
            <Box className="button-box button-quaternary">
              <Link to="/magazine" className="button-link">
                <Box className="button-content">
                  <img src={magazine} alt="Monthly Magazine" className="button-image" />
                  <Typography variant="h6" className="button-text">
                    Monthly Magazine
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box className="button-box button-tertiary">
              <Link to="/usachapter" className="button-link">
                <Box className="button-content">
                  <img src={news} alt="USA Chapter" className="button-image" />
                  <Typography variant="h6" className="button-text">
                    USA Chapter
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>




        </Grid>
     
    </Box>
  );
}

export default Buttons;