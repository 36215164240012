import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  Box, 
  Button, 
  Card, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  
  Typography, 
  IconButton, 
  Container, 
  Grid, 
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import { useNavigate } from 'react-router-dom';
import { getBooks, deleteBookById,getAuthors, generateSignedUrl } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const StyledTableCell = styled(TableCell)(({ backgroundColor }) => ({
  backgroundColor: backgroundColor,
  color: 'white',
  fontWeight: 'bold',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: '#A56266',
    color: 'white',
  },
  '& .MuiDialogActions-root': {
    padding: '16px 24px',
  },
  '& .MuiButton-contained': {
    backgroundColor: '#A56266',
    '&:hover': {
      backgroundColor: '#8b4f53',
    },
  },
}));

const AdminPublication = ({ ...props }) => {
  const navigate = useNavigate();
  const [publications, setPublications] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState({});
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [publicationToDelete, setPublicationToDelete] = useState(null);
  const [selectedPublication, setSelectedPublication] = useState(null);

  useEffect(() => {
    fetchPublications();
  }, []);

  const fetchPublications = async () => {
    try {
      const [booksResponse, authorsResponse] = await Promise.all([getBooks(), fetchAuthors()]);
      const authors = Object.fromEntries(authorsResponse.map((author) => [author._id, author.name]));
      const books = booksResponse?.data?.books || [];
  
      // Map author ID to name
      const booksWithAuthorNames = books.map((book) => ({
        ...book,
        authorName: authors[book.author] || 'Unknown Author',
      }));
  
      setPublications(booksWithAuthorNames);
    } catch (error) {
      toast.error('Error fetching publications');
    }
  };
  

  const handleClick = () => {
    navigate('/admin/newpublications');
  };
  const fetchAuthors = async () => {
    try {
      const response = await getAuthors();
      return response?.data?.authors || [];
    } catch (error) {
      toast.error('Error fetching authors');
      return [];
    }
  };
  const openDeleteDialog = (publication) => {
    setSelectedPublication(publication);
    setPublicationToDelete(publication._id);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setPublicationToDelete(null);
    setSelectedPublication(null);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteBookById(publicationToDelete);
      toast.success(response?.data?.message || 'Publication deleted successfully');
      fetchPublications();
      closeDeleteDialog();
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error deleting publication');
    }
  };

  const handleEdit = (publicationId) => {
    navigate(`/admin/edit-publications/${publicationId}`);
  };

  const handleGenerateAndShare = async (publicationId) => {
    setLoading((prev) => ({ ...prev, [publicationId]: true }));
    try {
      const response = await generateSignedUrl(publicationId);
      if (response.data.success) {
        let bookUrl = response.data.bookUrl;
        const whatsappUrl = `https://wa.me/?text=Here's your book link: ${bookUrl}`;
        window.open(whatsappUrl, '_blank');
        toast.success('Link generated and ready to share!');
      } else {
        toast.error('Failed to generate link');
      }
    } catch (error) {
      toast.error('Error generating link');
    } finally {
      setLoading((prev) => ({ ...prev, [publicationId]: false }));
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredPublications = publications.filter(
    (pub) => pub.name && pub.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Box sx={{  mt: { xs: 8, sm: 12 }, display: 'flex', alignItems: 'center', gap: { xs: 14, sm: 88 } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: { xs: 3, sm: 2 } }}>
              Publication
            </Typography>
            <Button
              variant="contained"
              style={{ backgroundColor: "#A56266", color: "white" }}
              onClick={handleClick}
            >
              <AddIcon /> Create
            </Button>
          </Box>
          <Box sx={{ marginRight: { xs: -2, sm: 0 } }}>
            <TableContainer component={Item}>
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell backgroundColor="#A56266">Action</StyledTableCell>
                    <StyledTableCell backgroundColor="#d18898">Title</StyledTableCell>
                    <StyledTableCell backgroundColor="#caada7">Author</StyledTableCell>
                    <StyledTableCell backgroundColor="#A56266">Price</StyledTableCell>
                    <StyledTableCell backgroundColor="#d18898">Status</StyledTableCell>
                    <StyledTableCell backgroundColor="#caada7">Share</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPublications.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">No publications found</TableCell>
                    </TableRow>
                  ) : (
                    filteredPublications.map((pub, index) => (
                      <TableRow key={pub._id}>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <IconButton title="Edit Publication" onClick={() => handleEdit(pub._id)}>
                            <EditIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton title="Delete Publication" onClick={() => openDeleteDialog(pub)}>
                            <DeleteIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>{pub.name}</Typography>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
  {pub.authorName}
</TableCell>

                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          {pub.price}
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          {pub.status}
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: '#A56266', color: 'white' }}
                            onClick={() => handleGenerateAndShare(pub._id)}
                            disabled={loading[pub._id]}
                            startIcon={loading[pub._id] ? <CircularProgress size={20} color="inherit" /> : <ShareIcon />}
                          >
                            {loading[pub._id] ? 'Generating...' : 'Share'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
     
         <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Publication? This action cannot be undone.
                  </DialogContentText>
                </DialogContent>



<DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDelete} 
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      



      </Dialog>

      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminPublication;