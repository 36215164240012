import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardContent, CardMedia, Skeleton } from '@mui/material';
import { VideoCall, School, Info } from '@mui/icons-material';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import './Courses.css';
import Magazine from '../HomePage/Magazine';
import { getAllCourses } from '../../../Services/AdminServices';
import { toast } from 'react-hot-toast';
import { styled } from '@mui/system';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${aboutdetail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));


function Courses() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        setLoading(true);
        const response = await getAllCourses();
        
        if (response?.data?.success) {
          setCourses(response.data.courses || []);
        } else {
          toast.error('Failed to load courses');
        }
      } catch (error) {
        toast.error('Failed to load courses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleJoinClass = (classroomJoinLink) => {
    if (classroomJoinLink) {
      try {
        const url = classroomJoinLink.startsWith('http') ? classroomJoinLink : `https://${classroomJoinLink}`;
        window.open(url, '_blank', 'noopener,noreferrer');
      } catch (error) {
        toast.error('Unable to open classroom link');
      }
    } else {
      toast.error('Classroom link not available');
    }
  };

  const formatMeetLink = (link) => {
    if (!link) return '';
    const meetCode = link.split('/').pop();
    return `meet.google.com/.../${meetCode}`;
  };

  return (
    <div>
      {/* Hero Section with Modern Design */}
      <Box sx={{ mt: { xs: 0, lg: 3 } }}>
      <HeroSection>
          <HeroContent>
            <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
             Certificate In Christian Journalism
            </Typography>
            {/* <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
              Enhance Your spiritual Growth with Our Comprehensive Journalism Course
            </Typography> */}
          </HeroContent>
        </HeroSection>
        </Box>
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {loading ? (
          <Grid container spacing={4}>
            {[1, 2, 3, 4].map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item}>
                <Card sx={{ height: '100%', borderRadius: 2, boxShadow: '0 8px 24px rgba(0,0,0,0.12)', overflow: 'hidden'}}>
                  <Skeleton variant="rectangular" height={200}  />
                  <CardContent>
                    <Skeleton variant="text" height={40} width="80%" animation="wave" />
                    <Skeleton variant="text" height={20} animation="wave" />
                    <Skeleton variant="text" height={20} animation="wave" />
                    <Skeleton variant="rectangular" height={36} width="50%" sx={{ mt: 2 }} animation="wave" />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {Array.isArray(courses) && courses.map((course) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={course._id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    borderRadius: 2, 
                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)', 
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 16px 32px rgba(0,0,0,0.15)',
                    },
                    ml:{xs:3,md:0},
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      image={course.coverPhoto?.url || 'https://via.placeholder.com/400x200'}
                      alt={`Image for ${course.name}`}
                      sx={{ height: 200, objectFit: 'cover' }}
                    />
                    {course.classroomJoinLink && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 10,
                          right: 10,
                          backgroundColor: 'rgba(10, 34, 64, 0.8)',
                          color: 'white',
                          borderRadius: '50%',
                          width: 40,
                          height: 40,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <School />
                      </Box>
                    )}
                  </Box>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography 
                      variant="h5" 
                      component="h2" 
                      sx={{ 
                        fontWeight: 700, 
                        mb: 1,
                        fontSize: '1.25rem',
                        color: '#0a2240',
                        lineHeight: 1.3
                      }}
                    >
                      {course.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ 
                        mb: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        flexGrow: 1
                      }}
                    >
                      {course.description}
                    </Typography>
                    
                    {course.classroomJoinLink && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <VideoCall sx={{ color: '#0a2240', mr: 1, fontSize: 20 }} />
                        <Typography variant="body2" sx={{ color: '#0a2240', fontWeight: 500 }}>
                          {formatMeetLink(course.classroomJoinLink)}
                        </Typography>
                      </Box>
                    )}
                    
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: course.classroomJoinLink ? '#0a2240' : '#e0e0e0',
                        color: course.classroomJoinLink ? 'white' : '#666666',
                        py: 1,
                        fontWeight: 600,
                        borderRadius: 2,
                        boxShadow: course.classroomJoinLink ? '0 4px 12px rgba(10, 34, 64, 0.3)' : 'none',
                        '&:hover': {
                          backgroundColor: course.classroomJoinLink ? '#153456' : '#e0e0e0',
                          boxShadow: course.classroomJoinLink ? '0 6px 16px rgba(10, 34, 64, 0.4)' : 'none',
                        },
                      }}
                      onClick={() => handleJoinClass(course.classroomJoinLink)}
                      disabled={!course.classroomJoinLink}
                      startIcon={<VideoCall />}
                      fullWidth
                    >
                      {course.classroomJoinLink ? 'Join Class' : 'Not Available'}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        
        {!loading && courses.length === 0 && (
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <Info sx={{ fontSize: 60, color: '#0a2240', opacity: 0.5, mb: 2 }} />
            <Typography variant="h5" sx={{ color: '#0a2240', mb: 2 }}>
              No courses available
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Check back later for new courses
            </Typography>
          </Box>
        )}
      </Container>

      <Box sx={{ mb: 0, mt: 4 }}>
        <Magazine />
      </Box>
    </div>
  );
}

export default Courses;