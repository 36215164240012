import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton, Container, Grid, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
import { useNavigate } from 'react-router-dom';
import { getAllCourses, deleteCourseById } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';
import {  DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
const Item = styled(Card)({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
});

const MeetLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  textDecoration: 'none',
  color: '#1a73e8',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const AdminCourses = ({ ...props }) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
const [openDialog, setOpenDialog] = useState(false);
  const [deleteCourseId, setDeleteCourseId] = useState(null);




  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await getAllCourses();
      setCourses(response?.data?.courses || []);
    } catch (error) {
      toast.error('Error fetching courses');
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteCourseById(deleteCourseId);
      toast.success(response?.data?.message);
      setOpenDialog(false); // Close the dialog
      fetchCourses(); // Refresh the magazine list after deletion
    } catch (error) {
      toast.error('Error deleting course');
    }
  };


  const handleClick = () => {
    navigate('/admin/newcourse');
  };
  const openDeleteDialog = (courseId) => {
    setDeleteCourseId(courseId);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setDeleteCourseId(null);
  };


  

  const handleEdit = (courseId) => {
    navigate(`/admin/new-course/${courseId}`);
  };

  const formatMeetLink = (link) => {
    if (!link) return 'No link provided';
    try {
      const url = new URL(link);
      return `meet.google.com/${url.pathname.split('/').pop()}`;
    } catch {
      return link;
    }
  };

  const filteredCourses = courses.filter(
    (course) => course.name && course.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <Grid container spacing={2}>
        {/* Left Drawer Section */}
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        {/* Right Content Section */}
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Box sx={{ mt: { xs: 10, sm: 12 }, display: 'flex', alignItems: 'center', gap: { xs: 18, sm: 88 } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: { xs: 3, sm: 2 } }}>
              Courses
            </Typography>
            <Button
              variant="contained"
              style={{ backgroundColor: "#A56266", color: "white" }}
              onClick={handleClick}
            >
              <AddIcon /> Create
            </Button>
          </Box>

          <Box sx={{ marginRight: { xs: -2, sm: 0 } }}>
            <TableContainer component={Item} sx={{ padding: '10px' }}>
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
              <TableHead>
  <TableRow>
    <TableCell
      sx={{
        backgroundColor: "#d18898",
        color: "white",
        fontWeight: "bold",
        width: { xs: '15%', sm: 'auto' },
      }}
    >
      Actions
    </TableCell>
    
    <TableCell
      sx={{
        backgroundColor: "#A56266",
        color: "white",
        fontWeight: "bold",
        width: { xs: '25%', sm: 'auto' },
      }}
    >
      Course Name
    </TableCell>
    
    <TableCell
      sx={{
        backgroundColor: "#A56266",
        color: "white",
        fontWeight: "bold",
        width: { xs: '20%', sm: 'auto' },
      }}
    >
      Google Meet Link
    </TableCell>
    
    <TableCell
  sx={{
    backgroundColor: "#caada7",
    color: "white",
    fontWeight: "bold",
    width: { xs: '100%', sm: '600px' },
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    maxWidth: { xs: '150px', sm: '600px' }, // Control max width on mobile
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: { xs: '8px', sm: '16px' }, // Adjust padding for mobile
    fontSize: { xs: '14px', sm: '16px' }, // Smaller font on mobile
  }}
>
  Resource Person
</TableCell>
  </TableRow>
</TableHead>
                <TableBody>
                  {filteredCourses.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">No courses found</TableCell>
                    </TableRow>
                  ) : (
                    filteredCourses.map((course, index) => (
                      <TableRow key={course._id}>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <IconButton title="Edit course" onClick={() => handleEdit(course._id)}>
                            <EditIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton title="Delete course" onClick={() => openDeleteDialog(course._id)}>
                            <DeleteIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <span style={{ fontSize: 17, fontWeight: 'bold' }}>{course.name}</span>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          {course.classroomJoinLink ? (
                            <MeetLink href={course.classroomJoinLink} target="_blank" rel="noopener noreferrer">
                              <VideoCallIcon sx={{ fontSize: 20 }} />
                              {formatMeetLink(course.classroomJoinLink)}
                            </MeetLink>
                          ) : (
                            <span style={{ color: '#666' }}>No link provided</span>
                          )}
                        </TableCell>
                        <TableCell 
  align="left" 
  style={{ 
    backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8',
    padding: '8px',
    maxWidth: '150px', // Match header max-width
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' // Show ellipsis for long text on mobile
  }}
>
  <span style={{ 
    fontWeight: 600, 
    fontSize: { xs: '13px', sm: '14px' },
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }}>
    {course.description}
  </span>
</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this course? This action cannot be undone.
          </DialogContentText>
        </DialogContent>


<DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDelete} 
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>

        
      </Dialog>

      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminCourses;