import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFilteredNewsData } from '../../../Services/AdminServices';
import { saveToLocalStorage } from '../../../Helper/helper';
import parse from 'html-react-parser';
import './MainNews.css';

// Helper function to format the slug for the news detail URL
const formatNewsDetailUrl = (slug) => {
  return `/news-articles/getNewsBySlug/${encodeURIComponent(slug)}`;
};

const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';
  
  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });

  return imageUrl;
};

const getFilteredContent = (htmlContent, limit = 150) => {
  if (!htmlContent) return '';

  let textContent = '';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.type === 'text') {
        textContent += domNode.data + ' ';
      }
      if (domNode.name === 'img') {
        return null;
      }
      return domNode;
    },
  });

  return textContent.trim().replace(/\s+/g, ' ').slice(0, limit) + '...';
};

const renderMainNewsContent = (news, navigate) => {
  const imageUrl = extractImageFromContent(news.content);
  const formattedUrl = formatNewsDetailUrl(news.slug);
  const filteredContent = getFilteredContent(news.content, 200);

  return (
    <Box 
      sx={{ 
        backgroundColor: '#fafafa',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        height: '100%',
        
      }}
    >
      <Box sx={{ padding: { xs: '16px', md: '24px' } }}>
        <Link to={formattedUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography
            component="h3"
            sx={{
              margin: 0,
              marginBottom: '8px',
              color: '#1a1a1a',
              transition: 'color 0.3s',
              textAlign: 'left',
              
              fontSize: { xs: '1.4rem', md: '1.8rem' },
              fontWeight: 700,
              '&:hover': { color: '#007aff' }
            }}
          >
            {news.title}
          </Typography>
        </Link>

        <Typography
          variant="body2"
          sx={{
            color: 'rgba(0, 0, 0, 0.7)',
            fontSize: { xs: '1rem', md: '1.1rem' },
            lineHeight: 1.6,
            
            textAlign: 'justify',
            
            display: '-webkit-box',
            WebkitLineClamp: 3, // Limit to 3 lines
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
        >
          {filteredContent}
          <Box component="span" sx={{ ml: {xs:0,md:6}, color: 'primary.main', cursor: 'pointer', fontWeight: 500 }}
            onClick={() => console.log('Read More clicked')}>
            Read More
          </Box>
        </Typography>
        
          
      </Box>

      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          alt="Image"
          src={imageUrl}
          sx={{
            width: '100%',
            height: { xs: 300, sm: 350, md: 350},
            objectFit: 'cover',
            cursor: 'pointer'
          }}
          onClick={() => {
            saveToLocalStorage(window.location.pathname, window.scrollY);
            navigate(formattedUrl);
          }}
        />
      </Box>
    </Box>
  );
};






function MainNews() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const data = { categoryName: '', type: 'news', count: 4 };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setNewsData(response.data.latestNews);
        } else {
          setError('No news data found.');
        }
      } catch (err) {
        setError('Failed to fetch news: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading news...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!newsData || newsData.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No news articles available.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: { xs: 0, md: 3 } }}>
      <Grid container spacing={{ xs: 1, md: 3 }}>
  <Grid item xs={12} md={7}>
    {newsData && newsData.length > 0 && (
      <Link to={formatNewsDetailUrl(newsData[0].slug)} style={{ textDecoration: 'none', color: 'inherit' }}>
        {renderMainNewsContent(newsData[0], navigate)}
      </Link>
    )}
  </Grid>


        <Grid item xs={12} md={5}>
          <Box sx={{
            backgroundColor: '#fafafa',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            padding: { xs: '-20px', md: '16px' },
           
            height: '100%'
          }}>
            {newsData.slice(1, 5).map((item, index) => {
              const imageUrl = extractImageFromContent(item.content);
              const filteredContent = getFilteredContent(item.content, 100);
              const formattedUrl = formatNewsDetailUrl(item.slug);

              return (
                <Box key={index} sx={{ mb: index !== 1 ? 2 : 0 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Box
                        component="img"
                        alt={`News ${index + 1}`}
                        src={imageUrl}
                        sx={{
                          width: '100%',
                          height: 150,
                          objectFit: 'cover',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          ml: { xs: 3, sm: 0 },
                          
                        }}
                        onClick={() => {
                          saveToLocalStorage(window.location.pathname, window.scrollY);
                          navigate(formattedUrl);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
  <Link to={formattedUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
    <Typography
      sx={{
        fontSize: { xs: '1rem', sm: '1rem', md: '1rem' },
        fontWeight: 600,
        mb: 1,
        color: '#1a1a1a',
        transition: 'color 0.3s',
        '&:hover': { color: '#007aff' },
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        ml: { xs: 4, sm: 0 },
        mt: { xs: 0, sm: 3 },
      }}
    >
      {item.title}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ml: { xs: 4, sm: 0 },
        mr: { xs: 1, sm: 0 },
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: { xs: '0.9rem', sm: '0.875rem', md: '0.9rem' },
          lineHeight: 1.5,
          textAlign:  'justify',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          flex: 1,
          mb:1
        }}
      >
        {filteredContent}
      </Typography>
      
    </Box>
    <Box
        component="span"
        sx={{
          ml:{xs:4,md:0},
          
          color: 'primary.main',
          cursor: 'pointer',
          fontWeight: 500,
          whiteSpace: 'nowrap',
        }}
        onClick={() => console.log('Read More clicked')}
      >
        Read More
      </Box>
  </Link>
</Grid>

                  </Grid>
                  {index < 2 && <Divider sx={{ marginY: 2 }} />}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainNews;