import React from 'react';
import { Routes, Route} from 'react-router-dom';
import Dashboard from '../Admin/Dashboard/Dashboard';
import AdminCourses from '../Admin/Pages/AdminCourses';
import AdminPublications from '../Admin/Pages/AdminPublication';
import AdminAds from '../Admin/Pages/AdminAds';
import AdminCategories from '../Admin/Pages/AdminCategories';
import CreateNews from '../Admin/Pages/CreateNews';
import NewCategory from '../Admin/Pages/NewCategory';
import NewCourse from '../Admin/Pages/NewCourse';
import AdminMagazine from '../Admin/Pages/AdminMagazine';
import NewMagazine from '../Admin/Pages/NewMagazine';
import NewPublication from '../Admin/Pages/NewPublication';
import AdminAuthors from '../Admin/Pages/AdminAuthors'
import NewAuthor from '../Admin/Pages/NewAuthor';
import Publishednews from '../Admin/Pages/Publishednews';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminProfile from '../Admin/Pages/AdminProfile';
import AdminPayment from '../Admin/Pages/AdminPayement';

const theme = createTheme({
  typography: {
    fontFamily: `"Noto Serif", "Noto Serif Malayalam", serif`,
  },
});




const AdminRoutes = () => (
  


   <ThemeProvider theme={theme}>
  <Routes>
    <Route path="/" element={<Dashboard  />} />
    <Route path="/courses" element={<AdminCourses />} />
    <Route path="/authors" element={<AdminAuthors />} />
    <Route path="edit-author/:id" element={<NewAuthor />} />
   
    <Route path="/publications" element={<AdminPublications />} />
    <Route path="/newpublications" element={<NewPublication />} />
    <Route path="edit-publications/:pId" element={<NewPublication />} />
    <Route path="/ads" element={<AdminAds />} />
    <Route path="/magazine" element={<AdminMagazine />} />
    <Route path="/newmagazine" element={<NewMagazine />} />
    <Route path="edit-magazine/:id" element={<NewMagazine />} />

    <Route path="/publish" element={<Publishednews />} />
    
    <Route path="/edit-news/:slug" element={<CreateNews />} />
    <Route path="/newauthor" element={<NewAuthor />} />
    <Route path="/categories" element={<AdminCategories />} />
    <Route path="/newcourse" element={<NewCourse />} />
    <Route path="/new-course/:id" element={<NewCourse />} />
    <Route path="/newcategories" element={<NewCategory />} />
    <Route path="edit-category/:catId" element={<NewCategory />} />
  

    <Route path="/create-news" element={<CreateNews />} />
    <Route path="/Profile" element={<AdminProfile />} />
    <Route path="/Payment" element={<AdminPayment />} />
   
  </Routes>
  </ThemeProvider>
);  

export default AdminRoutes;
