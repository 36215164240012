import React, { useEffect } from 'react';
import Buttons from '../Components/HomePage/Buttons';
import MainNews from '../Components/HomePage/MainNews';
import SpiritualNews from '../Components/HomePage/SpiritualNews';
import SecularNews from '../Components/HomePage/SecularNews';
import Footer from '../Components/HomePage/Footer';
import Articleshome from '../Components/HomePage/Articleshome';
import Magazine from '../Components/HomePage/Magazine';



function Home() {


    useEffect(() => {
        window.scrollTo(0, 0); 
      }, []);
    


    return (
        <div >
            <div>
                <MainNews />
            </div>
            
            <div  >
                <SpiritualNews />
            </div>
            
            <div >
                <SecularNews />
            </div>
            
            <div >
                <Articleshome />
            </div>
            <div >
                <Magazine />
            </div>
        </div>
    );
}

export default Home;
