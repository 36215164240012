import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button,Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { getFilteredNewsData } from '../../../Services/AdminServices';
import { saveToLocalStorage } from '../../../Helper/helper';
import parse from 'html-react-parser';

const formatNewsDetailUrl = (slug) => {
  return `/news-articles/getNewsBySlug/${encodeURIComponent(slug)}`;
};

const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';
  
  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });
  return imageUrl;
};

const getFilteredContent = (htmlContent, limit = 150) => {
  if (!htmlContent) return '';

  let textContent = '';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.type === 'text') {
        textContent += domNode.data + ' ';
      }
      if (domNode.name === 'img') {
        return null;
      }
      return domNode;
    },
  });

  return textContent.trim().replace(/\s+/g, ' ').slice(0, limit) + '...';
};

const renderMainNewsContent = (news, navigate) => {
  const imageUrl = extractImageFromContent(news.content);
  const formattedUrl = formatNewsDetailUrl(news.slug);

  return (
    <Box 
      sx={{ 
        backgroundColor: '#fafafa',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Box sx={{ padding: { xs: '16px', md: '24px' } }}>
        <Link to={formattedUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
          <h3 style={{
            margin: 0,
            marginBottom: '2px',
            color: '#1a1a1a',
            transition: 'color 0.3s',
            textAlign: 'left',
            fontSize: '1.4rem',
            fontWeight: 'bold'
          }}>
            {news.title}
          </h3>
        </Link>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          component="img"
          alt="News Image"
          src={imageUrl}
          sx={{
            width: '100%',
            height: { xs: 250, sm: 300, md: 350 },
            objectFit: 'cover',
            cursor: 'pointer'
          }}
          onClick={() => {
            saveToLocalStorage(window.location.pathname, window.scrollY);
            navigate(formattedUrl);
          }}
        />
      </Box>
    </Box>
  );
};

function UsaChapter() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        const data = { categoryName: 'USA Chapter', type: 'news', count: 8 };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setNewsData(response.data.latestNews);
        } else {
          setError('No news data found.');
        }
      } catch (err) {
        setError('Failed to fetch news: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading news...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', p: { xs: 2, md: 4 } }}>
      {/* Header */}
      <Box sx={{ mb: 3 }}>
        <Button
          variant="contained"
          sx={{ 
            fontWeight: 'bold',
            backgroundColor: '#AD62AA',
            '&:hover': { backgroundColor: '#04049a' }
          }}
        >
          USA Chapter News
        </Button>
      </Box>

      {/* Main News Section - Two Column Layout */}
      <Grid container spacing={1} sx={{ mb: 4 }}>
        {newsData.slice(0, 2).map((news, index) => (
          <Grid item xs={12} md={6} key={index}>
            {renderMainNewsContent(news, navigate)}
          </Grid>
        ))}
      </Grid>

      {/* Secondary News Section - Four Column Layout */}
      <Grid container spacing={1}>
  {newsData.slice(2, 6).map((news, index) => (
    <React.Fragment key={index}>
      <Grid item xs={12} sm={6} md={3}>
        <Box sx={{ 
          borderRadius: '8px',
          overflow: 'hidden',
          height: '100%'
        }}>
          <Box
            component="img"
            src={extractImageFromContent(news.content)}
            alt={`News ${index + 1}`}
            sx={{
              width: '100%',
              height: 200,
              objectFit: 'cover',
              cursor: 'pointer'
            }}
            onClick={() => {
              saveToLocalStorage(window.location.pathname, window.scrollY);
              navigate(formatNewsDetailUrl(news.slug));
            }}
          />
          <Box sx={{ p: 2 }}>
            <Link 
              to={formatNewsDetailUrl(news.slug)} 
              style={{ textDecoration: 'none' }}
            >
              <Typography 
                sx={{ 
                  color: '#1a1a1a',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  mb: 1,
                  minHeight: '3em',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  '&:hover': { color: '#007aff' }
                }}
              >
                {news.title}
              </Typography>
            </Link>
            <Typography 
              variant="body2" 
              sx={{
                color: 'text.secondary',
                fontSize: '0.9rem',
                lineHeight: 1.5,
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden'
              }}
            >
              {getFilteredContent(news.content, 100)}
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* Add divider only on xs screens and if it's not the last item */}
      {index < newsData.slice(2, 6).length - 1 && (
        <Box 
          sx={{ 
            width: '100%', 
            display: { xs: 'block', sm: 'none' },
            my: 2
          }}
        >
          <Divider />
        </Box>
      )}
    </React.Fragment>
  ))}
</Grid>
    </Box>
  );
}

export default UsaChapter;